import axios from 'axios'
// export const url_backend = `http://127.0.0.1:8000`
// export const url_backend = `http://127.0.0.1`
export const url_backend = process.env.REACT_APP_API_URL

export const loginURL = `localhost:3000/login`
export const urlMediaUsu = `${url_backend}/api/media/usuarios`
export const urlMediaPro = `${url_backend}/api/media/proyectos`
export const urlMediaEnc = `${url_backend}/api/media/encuestas`
export const urlMediaPub = `${url_backend}/api/media/documents`

export const chatHost = process.env.REACT_APP_HOST_CHAT
export const chatIP = process.env.REACT_APP_IP_CHAT
//export const chatIP = `http://82.223.48.177:5180`

export const auth = {
    login: function (data) {
        return axios.post(url_backend + `/api/auth/login`, data)
    },
    registro: function (data) {
        return axios.post(url_backend + `/api/auth/register`, data)
    },
    getProfile: function (token) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + token
        return axios.get(url_backend + `/api/auth/user-profile`)
    },
    verImg: function(url) {
        axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('auth')
        /*return axios.get(url, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem('auth')
            }
        })*/
        return axios({
            url: url,
            method: 'GET',
            responseType: 'blob', // important
        })
    }
}

export const userData = {
    getUsers: function () {
        return axios.get(url_backend + `/api/users/getUsers`)
    },
    getUser: function (id) {
        return axios.get(url_backend + `/api/users/getUser/${id}`)
    },
    getUsersEliminados: function () {
        return axios.get(url_backend + `/api/users/getUsersEliminados`)
    },
    validaUser: function (id) {
        return axios.post(url_backend + `/api/users/validaUser/${id}`)
    },
    bloqueaUser: function (id) {
        return axios.post(url_backend + `/api/users/bloqueaUser/${id}`)
    },
    visibleUser: function (id) {
        return axios.post(url_backend + `/api/users/visibleUser/${id}`)
    },
    deleteUser: function (id) {
        return axios.post(url_backend + `/api/users/deleteUser/${id}`)
    },
    restoreUser: function (id) {
        return axios.post(url_backend + `/api/users/restoreUser/${id}`)
    },
    editaUser: function (data, id) {
        return axios.post(url_backend + `/api/users/editaUser/${id}`, data)
    },
    editaPass: function (password, new_password, id) {
        return axios.post(url_backend + `/api/users/editaPass/${id}`, { password: password, new_password: new_password })
    },
    editaImage: function (image, id) {
        return axios.post(url_backend + `/api/users/editaImage/${id}`, { imagen_nombre: image })
    },
    editaComu: function (comu, id) {
        const datos = {
            comu: comu
        }
        return axios.post(url_backend + `/api/users/editaComu/${id}`, datos)
    },
    getEspecialidades: function () {
        return axios.get(url_backend + `/api/users/getEspecialidades`)
    },
    getHospitales: function () {
        return axios.get(url_backend + `/api/users/getHospitales`)
    },
    getImage: function (image) {
        return axios.get(url_backend + `/api/media/usuarios/${image}`)
    },
    uploadImage: function (data) {
        return axios.post(url_backend + `/api/users/fileStore`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    },
    cambioPass: function (id, password, new_password) {
        const user = {
            password: password,
            new_password: new_password
        }
        //axios.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("auth")
        return axios.post(url_backend + `/api/users/changepass/${id}`, user)
    },
    recuperarPass: function (formData) {
        return axios.post(url_backend + `/api/users/recuperaPass`, formData)
    },
    enviarSoporte: function (formData) {
        return axios.post(url_backend + `/api/users/enviarSoporte`, formData)
    },
    saveSoportePwd:function(id,password){

        const data = {
            id:id,
            password:password
        }
        return axios.post(url_backend+`/api/users/soportepwd`, data)
    },
    getIp: function(){
        return axios.post(url_backend+`/api/users/getIp`)
    }
}

export const proyectosData = {
    getProyectos: function () {
        return axios.get(url_backend + `/api/proyectos/getProyectos`)
    },
    getProyecto: function (id) {
        return axios.get(url_backend + `/api/proyectos/proyecto/${id}`)
    },
    getProyectosEliminados: function () {
        return axios.get(url_backend + `/api/proyectos/getProyectosEliminados`)
    },
    saveProyecto: function (data) {
        return axios.post(url_backend + `/api/proyectos/save`, data)
    },
    saveProyecto2: function (data) {
        return axios.post(url_backend + `/api/proyectos/save2`, data)
    },
    editaProyecto: function (data, id) {
        return axios.post(url_backend + `/api/proyectos/edit/${id}`, data)
    },
    editaProyecto2: function (data, id) {
        return axios.post(url_backend + `/api/proyectos/edit2/${id}`, data)
    },
    ocultarProyecto: function (id) {
        return axios.post(url_backend + `/api/proyectos/ocultarProyecto/${id}`)
    },
    deleteProyecto: function (id) {
        return axios.post(url_backend + `/api/proyectos/deleteProyecto/${id}`)
    },
    restoreProyecto: function (id) {
        return axios.post(url_backend + `/api/proyectos/restoreProyecto/${id}`)
    },
    getImage: function (image) {
        return axios.get(url_backend + `/api/media/proyectos/${image}`)
    },
    uploadImage: function (data) {
        return axios.post(url_backend + `/api/proyectos/fileStore`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
    },
}

export const encuestasData = {
    getEncuestas: function () {
        return axios.get(url_backend + `/api/encuestas/getEncuestas`)
    },
    getEncuesta: function (id) {
        return axios.get(url_backend + `/api/encuestas/encuesta/${id}`)
    },
    getEncuestasEliminadas: function () {
        return axios.get(url_backend + `/api/encuestas/getEncuestasEliminados`)
    },
    getEncuestasFront: function (id, page) {
        return axios.get(url_backend + `/api/encuestas/encuestas/${id}?page=${page}`)
    },
    getEncuestasFrontBuscador: function (id, texto, page) {
        return axios.get(url_backend + `/api/encuestas/encuestasbuscar/${id}/${texto}?page=${page}`)
    },
    getConfiguraciones: function () {
        return axios.get(url_backend + `/api/encuestas/getConfiguraciones`)
    },
    saveEncuesta: function (data) {
        return axios.post(url_backend + `/api/encuestas/save`, data, {
            headers: {
                "content-Type": "multipart/form-data",
            }
        })
    },
    editaEncuesta: function (data, id) {
        return axios.post(url_backend + `/api/encuestas/edit/${id}`, data, {
            headers: {
                "content-Type": "multipart/form-data",
            }
        })
    },
    ocultarEncuesta: function (id) {
        return axios.post(url_backend + `/api/encuestas/ocultarEncuesta/${id}`)
    },
    deleteEncuesta: function (id) {
        return axios.post(url_backend + `/api/encuestas/deleteEncuesta/${id}`)
    },
    restoreEncuesta: function (id) {
        return axios.post(url_backend + `/api/encuestas/restoreEncuesta/${id}`)
    },
    getImage: function (image) {
        return axios.get(url_backend + `/api/media/encuestas/${image}`)
    },
    uploadImage: function (data) {
        return axios.post(url_backend + `/api/encuestas/fileStore`, data, {
            headers: {
                "content-Type": "multipart/form-data",
            }
        })
    },
    saveRespuesta: function (formData) {
        return axios.post(url_backend + `/api/encuestas/saveRespuesta`, formData)
    }
}

export const publicacionesMuro = {
    postPublicacionMuro: function (data, onUploadProgress) {
        axios.defaults.headers['Access-Control-Allow-Origin'] = '*';
        return axios.post(url_backend + `/api/muro/muro`, data, {
            headers: {
                'content-type': 'multipart/form-data',
            },
            onUploadProgress
        })
    },
    getPublicacionesAll: function () {
        return axios.get(url_backend + `/api/muro/muro`)
    },

    getPublicaciones: function (id, page) {
        return axios.get(url_backend + `/api/muro/muro/${id}?page=${page}`)
    },
    deletePublicacion: function (id) {
        return axios.delete(url_backend + `/api/muro/publicacion/${id}`)
    },
    getPublicacionesBuscador: function (id, texto, page) {
        return axios.get(url_backend + `/api/muro/murobuscar/${id}/${texto}?page=${page}`)
    },
    getImage: function (image) {
        return axios.get(url_backend + `/api/media/documents/${image}`)
    },
}

export const comentarios = {
    postComentarioPrinci: function (data, onUploadProgress) {
        return axios.post(url_backend + `/api/comentarios/comentariopubli`, data, {
            onUploadProgress
        })
    },
    postComentarioContestacion: function (data) {
        return axios.post(url_backend + `/api/comentarios/comentariocomen`, data)
    },
    getComentariosPublicacion: function (idPublicacion) {
        return axios.get(url_backend + `/api/comentarios/comentarios/${idPublicacion}`)
    },
    deleteComentarios: function (idComentario, idPublicacion) {
        return axios.delete((url_backend + `/api/comentarios/comentarios/${idComentario}/${idPublicacion}`))
    }
}

export const agenda = {
    postAgenda: function (data, onUploadProgress) {
        return axios.post(url_backend + `/api/agenda/agenda`, data, {
            onUploadProgress
        })
    },
    editAgenda: function (id, data, onUploadProgress) {
        return axios.post(url_backend + `/api/agenda/editAgenda/${id}`, data, {
            onUploadProgress
        })
    },
    publicarAgenda: function (id) {
        return axios.post(url_backend + `/api/agenda/publicarAgenda/${id}`)
    },
    getAll: function () {
        return axios.get(url_backend + `/api/agenda/agenda`)
    },
    getAgenda: function (id) {
        return axios.get(url_backend + `/api/agenda/agenda/${id}`)
    },
    deleteEvento: function (id) {
        return axios.delete(url_backend + `/api/agenda/agenda/${id}`)
    },
}

export const alertas = {
    getAll: function (id) {
        return axios.get(url_backend + `/api/alertas/alertas/${id}`)
    },
    leerTodas: function (id) {
        return axios.get(url_backend + `/api/alertas/readAll/${id}`)
    },
    leerAlerta: function (id, alerta) {
        return axios.get(url_backend + `/api/alertas/readOne/${id}/${alerta}`)
    }
}

export const documentos = {
    postDocumento: function (data, onUploadProgress) {
        return axios.post(url_backend + `/api/documentacion/newDocumento`, data, {
            onUploadProgress
        })
    },
    editDocumento: function (id, data, onUploadProgress) {
        return axios.post(url_backend + `/api/documentacion/editDocumento/${id}`, data, {
            onUploadProgress
        })
    },
    publicarDocumento: function (id) {
        return axios.post(url_backend + `/api/documentacion/publicarDocumento/${id}`)
    },
    getAll: function () {
        return axios.get(url_backend + `/api/documentacion/documentacion`)
    },
    getTopTen: function () {
        return axios.get(url_backend + `/api/documentacion/getTopTen`)
    },
    getDocumentacion: function (id) {
        return axios.get(url_backend + `/api/documentacion/documentacion/${id}`)
    },
    getDocumentacionCarpetas: function (id) {
        return axios.get(url_backend + `/api/documentacion/documentacionCarpetas/${id}`)
    },
    getCarpetasDocumentacion: function (id) {
        return axios.get(url_backend + `/api/documentacion/showCarpetasProyecto/${id}`)
    },
    deleteDocumento: function (id) {
        return axios.delete(url_backend + `/api/documentacion/deleteDoc/${id}`)
    },
    deleteFolder: function (id) {
        return axios.delete(url_backend + `/api/documentacion/deleteFolder/${id}`)
    },
}

export const concusoLogo = {
    postDocumento: function (data, onUploadProgress) {
        return axios.post(url_backend + `/api/concursoLogo/newDocumento`, data, {
            onUploadProgress
        })
    },
    getDocumentacion: function (id) {
        return axios.get(url_backend + `/api/concursoLogo/documentacion/${id}`)
    },
    deleteDocumento: function (id) {
        return axios.delete(url_backend + `/api/concursoLogo/deleteDoc/${id}`)
    },
}

export const enlaces = {
    postEnlace: function (data, onUploadProgress) {
        return axios.post(url_backend + `/api/enlaces/enlaces`, data, {
            onUploadProgress
        })
    },
    editEnlace: function (id, data, onUploadProgress) {
        return axios.post(url_backend + `/api/enlaces/editEnlace/${id}`, data, {
            onUploadProgress
        })
    },
    publicarEnalce: function (id) {
        return axios.post(url_backend + `/api/enlaces/publicarEnalce/${id}`)
    },
    getAll: function () {
        return axios.get(url_backend + `/api/enlaces/enlaces`)
    },
    getEnlaces: function (id) {
        return axios.get(url_backend + `/api/enlaces/enlaces/${id}`)
    },
    deleteEnlace: function (id) {
        return axios.delete(url_backend + `/api/enlaces/enlaces/${id}`)
    },
}

export const foro = {
    getTemas: function (page) {
        return axios.get(url_backend + `/api/foro/foroall?page=${page}`)
    },
    getTemasBuscador: function (texto, page) {
        return axios.get(url_backend + `/api/foro/forobuscar/${texto}?page=${page}`)
    },
    getTema: function (id) {
        return axios.get(url_backend + `/api/foro/tema/${id}`)
    },
    getRespuestas: function (id, page) {
        return axios.get(url_backend + `/api/foro/fororespuestas/${id}?page=${page}`)
    },
    postTema: function (data, onUploadProgress) {
        return axios.post(url_backend + `/api/foro/foro`, data, {
            onUploadProgress
        })
    },
    postRespuesta: function (id, data, onUploadProgress) {
        return axios.post(url_backend + `/api/foro/respuesta/${id}`, data, {
            onUploadProgress
        })
    },
    masVisita: function (id) {
        return axios.get(url_backend + `/api/foro/visita/${id}`)
    }
}

export const chat = {
    countConnected: function () {
        return axios.get(url_backend + `/api/chat/connected_users_number`)
        /*
        axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        return axios.get(`${chatIP}/api/connected_users_number`)
        */
    },
    register: function (name, password) {
        /*
        axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            user: name,
            host: chatHost,
            password: password
        }
        return axios.post(`${chatIP}/api/register`, data)
        */
        return axios.post(url_backend + `/api/chat/register`, {
            user: name,
            password: password
        })
    },
    unregister: function (name) {
        /*
        axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            user: name,
            host: chatHost
        }
        return axios.post(`${chatIP}/api/unregister`, data)
        */
        return axios.post(url_backend + `/api/chat/register`, {
            user: name
        })
    },
    checkAccount: function (name) {
        /*
        axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            user: name,
            host: chatHost
        }
        return axios.post(`${chatIP}/api/check_account`, data)
        */
        return axios.post(url_backend + `/api/chat/check_account`, {
            user: name
        })
    },
    actualizaLista: function () {
        /*
        axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            host: chatHost,
            group: "all_users"
        }
        return axios.post(`${chatIP}/api/push_alltoall`, data)
        */
        return axios.get(url_backend + `/api/chat/push_alltoall`)
    },
    createRoom: function (name, title) {
        return axios.post(url_backend + `/api/chat/create_room`, {
            name: name,
            title: title
        })
        /*
        axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            name: name,
            service: "conference."+chatHost,
            host: chatHost,
            options: [
                {
                    name: "members_only",
                    value: "true"
                },
                {
                    name: "allow_subscription",
                    value: "true"
                },
                {
                    name: "title",
                    value: title
                },
                {
                    name: "allow_user_invites",
                    value: "false"
                },
                {
                    name: "persistent",
                    value: "true"
                },
                {
                    name: "public",
                    value: "false"
                },
                {
                    name: "public_list",
                    value: "false"
                }
            ]
        }
        return axios.post(`${chatIP}/api/create_room_with_opts`, data)
        */
    },
    editRoom: function (name, title) {
        /*
        axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            name: name,
            service: "conference."+chatHost,
            option: "title",
            value: title
        }
        return axios.post(`${chatIP}/api/change_room_option`, data)
        */
        return axios.post(url_backend + `/api/chat/change_room_option`, {
            name: name,
            title: title
        })
    },
    subscribeRoom: function (user, nick, room) {
        //axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            user: user,
            nick: nick,
            room: `${room}@conference.${chatHost}`,
            nodes: "urn:xmpp:mucsub:nodes:presence,urn:xmpp:mucsub:nodes:messages,urn:xmpp:mucsub:nodes:affiliations,urn:xmpp:mucsub:nodes:subscribers"
        }
        //return axios.post(`${chatIP}/api/subscribe_room`, data)
        return axios.post(url_backend + `/api/chat/subscribe_room`, data)
    },
    unsubscribeRoom: function (user, room) {
        //axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            user: user,
            room: `${room}@conference.${chatHost}`
        }
        //return axios.post(`${chatIP}/api/unsubscribe_room`, data)
        return axios.post(url_backend + `/api/chat/unsubscribe_room`, data)
    },
    setRoomAffiliation: function (user, room) {
        //axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            name: room,
            service: "conference."+chatHost,
            jid: user+'@'+chatHost,
            affiliation: `member`
        }
        //return axios.post(`${chatIP}/api/set_room_affiliation`, data)
        return axios.post(url_backend + `/api/chat/set_room_affiliation`, data)
    },
    removeMember: function (user, room) {
        //axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            name: room,
            service: "conference."+chatHost,
            jid: user+'@'+chatHost,
            affiliation: `outcast`
        }
        //return axios.post(`${chatIP}/api/set_room_affiliation`, data)
        return axios.post(url_backend + `/api/chat/set_room_affiliation`, data)
    },
    getAffiliations: function (room) {
        //axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            name: room,
            service: "conference."+chatHost
        }
        //return axios.post(`${chatIP}/api/get_room_affiliations`, data)
        return axios.post(url_backend + `/api/chat/get_room_affiliations`, data)
    },
    getSubscribers: function (room) {
        //axios.defaults.headers.common['Authorization'] = "Bearer s5hP4ViQXraiszG21p1DYaIA2owK2t22"
        const data = {
            name: room,
            service: "conference."+chatHost
        }
        //return axios.post(`${chatIP}/api/get_subscribers`, data)
        return axios.post(url_backend + `/api/chat/get_subscribers`, data)
    }
}


export const videoExtensionesPermitidas = [
    "3g2",
    "3gp",
    "aaf",
    "asf",
    "avchd",
    "avi",
    "drc",
    "flv",
    "m2v",
    "m3u8",
    "m4p",
    "m4v",
    "mkv",
    "mng",
    "mov",
    "mp2",
    "mp4",
    "mpe",
    "mpeg",
    "mpg",
    "mpv",
    "mxf",
    "nsv",
    "ogg",
    "ogv",
    "qt",
    "rm",
    "rmvb",
    "roq",
    "svi",
    "vob",
    "webm",
    "wmv",
    "yuv"
]

export const audioExtensionesPermitidas = [
    "wav",
    "bwf",
    "raw",
    "aiff",
    "flac",
    "m4a",
    "pac",
    "tta",
    "wv",
    "ast",
    "aac",
    "mp2",
    "mp3",
    "amr",
    "s3m",
    "3gp",
    "act",
    "au",
    "dct",
    "dss",
    "gsm",
    "m4p",
    "mmf",
    "mpc",
    "ogg",
    "oga",
    "opus",
    "ra",
    "sln",
    "vox"
]

export const imageExtensionesPermitidas = [
    "ase",
    "art",
    "bmp",
    "blp",
    "cd5",
    "cit",
    "cpt",
    "cr2",
    "cut",
    "dds",
    "dib",
    "djvu",
    "egt",
    "exif",
    "gif",
    "gpl",
    "grf",
    "icns",
    "ico",
    "iff",
    "jng",
    "jpeg",
    "jpg",
    "jfif",
    "jp2",
    "jps",
    "lbm",
    "max",
    "miff",
    "mng",
    "msp",
    "nitf",
    "ota",
    "pbm",
    "pc1",
    "pc2",
    "pc3",
    "pcf",
    "pcx",
    "pdn",
    "pgm",
    "PI1",
    "PI2",
    "PI3",
    "pict",
    "pct",
    "pnm",
    "pns",
    "ppm",
    "psb",
    "psd",
    "pdd",
    "psp",
    "px",
    "pxm",
    "pxr",
    "qfx",
    "raw",
    "rle",
    "sct",
    "sgi",
    "rgb",
    "int",
    "bw",
    "tga",
    "tiff",
    "tif",
    "vtf",
    "xbm",
    "xcf",
    "xpm",
    "3dv",
    "amf",
    "ai",
    "awg",
    "cgm",
    "cdr",
    "cmx",
    "dxf",
    "e2d",
    "egt",
    "eps",
    "fs",
    "gbr",
    "odg",
    "svg",
    "stl",
    "vrml",
    "x3d",
    "sxd",
    "v2d",
    "vnd",
    "wmf",
    "emf",
    "art",
    "xar",
    "png",
    "webp",
    "jxr",
    "hdp",
    "wdp",
    "cur",
    "ecw",
    "iff",
    "lbm",
    "liff",
    "nrrd",
    "pam",
    "pcx",
    "pgf",
    "sgi",
    "rgb",
    "rgba",
    "bw",
    "int",
    "inta",
    "sid",
    "ras",
    "sun",
    "tga"
]