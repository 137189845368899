import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import axios from 'axios'
import './App.css';
import {
  Redirect,
  Route,
  Switch,
  BrowserRouter
} from 'react-router-dom'

import { auth, loginURL } from './data/Data';

// Pages
const Login = React.lazy(() => import('./components/Login'));
const CambiaPass = React.lazy(() => import('./components/CambiaPass'));
const Desarrollo = React.lazy(() => import('./components/Desarrollo'));

// Containers
const Layout = React.lazy(() => import('./components/Layout'));

const pathroute = window.location.pathname;
function compruebaKey() {
  if (localStorage.getItem('auth') === null) {
    return false;
  }
  return true;
}

function App() {
  const history = useHistory()
  const [loggued, setLoggued] = useState(false)
  const [ip, setIP] = useState(true);

  const [ip2, setIP2] = useState(true);

  // const getData = async () => {
  //   await axios.get('https://geolocation-db.com/json/').then(resp => {
  //     setIP(resp.data.IPv4)
  //     console.log(resp.data.IPv4)
  //     return resp.data.IPv4
  //   }).catch(err => {
  //     return ''
  //   })
  // }

  // const getData = async () => {
  //   const res = await axios.get('https://geolocation-db.com/json/') || ''
  //   console.log(res.data);
  //   setIP(res.data.IPv4)
  //   return res.data.IPv4
  // }
  // async function isLogin(){
  //   try{
      
  //    const res = await axios.get('https://geolocation-db.com/json/')
  //    console.log(res.data.IPv4)
  //    setIP(res.data.IPv4 === '217.127.200.155' ? true : false)
  //    return res.data.IPv4 === '217.127.200.155' ? true : false
  //   }catch{
  //     return false
  //   }
    
  // }

  useEffect(() => {
    // let ipAux = getData()
    // if (ipAux == '217.127.200.155') {
      // async function isLogin(){
      //   await axios.get('https://geolocation-db.com/json/')
      //   .then(response => setIP2(response.data.IPv4 === '217.127.200.155' ? true : false))
      //   .catch(() => setIP2(false))
      // }
      //isLogin()
      if (!localStorage.getItem('auth')) {
        localStorage.removeItem('auth')
        setLoggued(false)
      } else {
        auth.getProfile(localStorage.getItem('auth'))
          .then((response) => {
            setLoggued(true)
          })
          .catch(error => {
            localStorage.removeItem('auth')
            setLoggued(false)
            window.location.href = loginURL;
          })

      }
    // }
  }, [ip])


  /*const PublicRoute = ({ children, ...rest }) => {
    axios.get('https://geolocation-db.com/json/').then(res =>{
      if (res.data.IPv4 === '217.127.200.155') {
        console.log(res.data.IPv4)
        return (
          <Route
            {...rest}
          >
            {children}
          </Route>
        );
      } else {
        return (
          <Route
            {...rest}
            >    
            <Redirect to={{
                pathname: '/desarrollo'
              }} /> 
          </Route>
        );
      }
    }).catch(err => {
      console.info(err)
    })

  }*/

  function PrivateRoute({ component: Componente, ...rest }) {
    //let a = isLogin()
    return (
      <Route
        {...rest}
        render={props =>
          true ? 
            <Componente {...props} />
          :
          <Redirect to={{
            pathname: '/desarrollo'
          }} /> 
        }
      />
    );
  }

  return (
    <BrowserRouter>
      <React.Suspense fallback={'Cargando...'}>
        <Switch>
          {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/cambiaPass" name="Cambiar pass" render={props => <CambiaPass {...props} />} />
              <Route path="/" name="Muro" render={props => <Layout {...props} />} /> */}
              {
                ip2 && (
                  <PrivateRoute exact path="/login" name="Login Page" component={props => <Login {...props}/>} />
                )
              }
              {
                ip2 && (
                  <PrivateRoute exact path="/cambiaPass" name="Login Page" component={props => <CambiaPass {...props}/>} />
                )
              }
              {
                ip2 && (
                  <PrivateRoute path="/" name="Muro" component={props => <Layout {...props}/>} />
                )
              }
              {
                !ip2 && (
                  <Route path="/" name="Desarrollo" render={props => <Desarrollo {...props} />} />
                )
              }
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
}

export default App;
